var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("page-filters-section", [_c("h1", [_vm._v("Info")])]),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _vm._v(" 1 SCRL = $" + _vm._s(this.tokenPrice) + " "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("h3", [_vm._v("Package size: Prod")]),
                  _c("BarChartGenerator", {
                    attrs: {
                      "chart-options": _vm.chartOptions,
                      "chart-data": _vm.masterSizeChartData,
                      height: 200,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("h3", [_vm._v("Package size: Dev")]),
                  _c("BarChartGenerator", {
                    attrs: {
                      "chart-options": _vm.chartOptions,
                      "chart-data": _vm.developSizeChartData,
                      height: 200,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Staking")]),
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("tr", [
                      _c("th", [_vm._v("Name")]),
                      _c("th", [_vm._v("ATH amount")]),
                      _c("th", [_vm._v("Left amount")]),
                    ]),
                    _vm._l(
                      [
                        _vm.contracts["staking_1"],
                        _vm.contracts["staking_2"],
                        _vm.contracts["staking_3"],
                        _vm.contracts["staking_4"],
                      ],
                      function (contract) {
                        return _c("tr", { key: contract.address }, [
                          _c("td", [_vm._v(_vm._s(contract.name))]),
                          !contract.loaded
                            ? _c(
                                "td",
                                { attrs: { colspan: "2" } },
                                [
                                  _c("v-progress-circular", {
                                    attrs: { size: "20", indeterminate: "" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          contract.loaded
                            ? _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("localFormatNumber")(
                                        contract.maxAmount * _vm.stakeValue
                                      )
                                    ) +
                                    " SCRL (" +
                                    _vm._s(contract.maxAmount) +
                                    "x) "
                                ),
                              ])
                            : _vm._e(),
                          contract.loaded
                            ? _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("localFormatNumber")(
                                        _vm._f("removeDecimals")(
                                          contract.balance
                                        )
                                      )
                                    ) +
                                    " SCRL ($" +
                                    _vm._s(
                                      _vm._f("localFormatNumber")(
                                        _vm._f("removeDecimals")(
                                          contract.balance * _vm.tokenPrice
                                        )
                                      )
                                    ) +
                                    ") | (" +
                                    _vm._s(
                                      _vm.calculatePercent(
                                        contract.maxAmount * _vm.stakeValue,
                                        contract.balance
                                      )
                                    ) +
                                    "%) "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Token")]),
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("tr", [
                      _c("th", { staticStyle: { width: "250px" } }, [
                        _vm._v("Name"),
                      ]),
                      _c("th", [_vm._v("Amount")]),
                    ]),
                    _vm._l(_vm.tokenAddresses, function (contract) {
                      return _c("tr", { key: contract.address }, [
                        _c("td", [_vm._v(_vm._s(contract.name))]),
                        !contract.loaded
                          ? _c(
                              "td",
                              { attrs: { colspan: "2" } },
                              [
                                _c("v-progress-circular", {
                                  attrs: { size: "20", indeterminate: "" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        contract.loaded
                          ? _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(contract.balance)
                                    )
                                  ) +
                                  " SCRL ($" +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(
                                        contract.balance * _vm.tokenPrice
                                      )
                                    )
                                  ) +
                                  ") "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                  ],
                  2
                ),
                _vm._v(
                  " -total amount -burned (% of total, % of all unlocked) (total supply(const) - amount) -amount in our contracts (mini table) -amount in LP -amount unlocked (in ppl's hand) (create endpoint also for coingecko) -amount unlocked - amount on stakes (token on wallets) -chart with: burned/unlocked/uncloked-ppl hands -check SCRL/BNB balance of all contracts we use (to know if somebody send extra $ by accident) "
                ),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Vesting")]),
                _c("b", [_vm._v("Main contract status")]),
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("tr", [
                      _c("th", [_vm._v("Claimed")]),
                      _c("th", [_vm._v("Balance")]),
                    ]),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("localFormatNumber")(
                              _vm.vestingOverallInfo.claimedPercent
                            )
                          ) + "%"
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("localFormatNumber")(
                              _vm._f("removeDecimals")(
                                _vm.vestingOverallInfo.missingTokens
                              )
                            )
                          ) +
                            " SCRL (" +
                            _vm._s(
                              _vm._f("localFormatNumber")(
                                _vm.vestingOverallInfo.balancePercent
                              )
                            ) +
                            "%) "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("br"),
                _c("b", [_vm._v("VCs")]),
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("tr", [
                      _c("th", [_vm._v("Name")]),
                      _c("th", [_vm._v("Claimed")]),
                      _c("th", [_vm._v("Total")]),
                      _c("th", [_vm._v("Claimed in %")]),
                    ]),
                    _vm._l(_vm.vestings, function (row) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.name))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(row.claimed)
                                )
                              ) +
                              " ($" +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(
                                    row.claimed * _vm.tokenPrice
                                  )
                                )
                              ) +
                              ") "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(row.total)
                                )
                              ) +
                              " ($" +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(
                                    row.total * _vm.tokenPrice
                                  )
                                )
                              ) +
                              ") "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("localFormatNumber")(
                                (row.claimed / row.total) * 100
                              )
                            ) + "%"
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _c("br"),
                _c("b", [_vm._v("Advisors")]),
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("tr", [
                      _c("th", [_vm._v("Name")]),
                      _c("th", [_vm._v("Claimed")]),
                      _c("th", [_vm._v("Total")]),
                      _c("th", [_vm._v("Claimed in %")]),
                    ]),
                    _vm._l(_vm.advisorVestings, function (row) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.name))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(row.claimed)
                                )
                              ) +
                              " ($" +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(
                                    row.claimed * _vm.tokenPrice
                                  )
                                )
                              ) +
                              ") "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(row.total)
                                )
                              ) +
                              " ($" +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm._f("removeDecimals")(
                                    row.total * _vm.tokenPrice
                                  )
                                )
                              ) +
                              ") "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("localFormatNumber")(
                                (row.claimed / row.total) * 100
                              )
                            ) + "%"
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _vm._v(
                  " -amount unlocked -amount to unlock -amount left SCRL on contract (with %) -amount SCRL required to be on contract (- or +) -mini table: our VCs/KOLs: tokens taken/to take/end in days/url to wallet on bsc "
                ),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("MarketMaker")]),
                _vm._v(" -sizes of MM accounts "),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Wizards")]),
                _vm._v(
                  " -total amount -genetic mini-table: % of parts in pool -(external?) browser of wizard images -levels minitable: % of wizards on levels "
                ),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Lands")]),
                _vm._v(
                  " -total amount -mini-table with %: rarity/element/type -not yet taken from 1st sale "
                ),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Staking Map")]),
                _vm._v(" -max possible day for now "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }