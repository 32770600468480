<template>
    <v-container fluid>
        <page-filters-section>
            <h1>Info</h1>
        </page-filters-section>

        <div class="page-content">
            1 SCRL = ${{ this.tokenPrice }}
            <v-row>
                <v-col cols="6">
                    <h3>Package size: Prod</h3>
                    <BarChartGenerator
                        :chart-options="chartOptions"
                        :chart-data="masterSizeChartData"
                        :height="200"
                    />
                </v-col>
                <v-col cols="6">
                    <h3>Package size: Dev</h3>
                    <BarChartGenerator
                        :chart-options="chartOptions"
                        :chart-data="developSizeChartData"
                        :height="200"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <h3>Staking</h3>
                    <table border="1" style="width: 100%">
                        <tr>
                            <th>Name</th>
                            <th>ATH amount</th>
                            <th>Left amount</th>
                        </tr>
                        <tr
                            v-for="contract in [contracts['staking_1'],contracts['staking_2'],contracts['staking_3'],contracts['staking_4']]"
                            :key="contract.address"
                        >
                            <td>{{ contract.name }}</td>
                            <td colspan="2" v-if="!contract.loaded">
                                <v-progress-circular
                                    size="20"
                                    indeterminate
                                ></v-progress-circular>
                            </td>
                            <td v-if="contract.loaded">
                                {{ (contract.maxAmount * stakeValue) | localFormatNumber }} SCRL
                                ({{ contract.maxAmount }}x)
                            </td>
                            <td v-if="contract.loaded">
                                {{ contract.balance | removeDecimals | localFormatNumber }} SCRL
                                (${{ contract.balance * tokenPrice | removeDecimals | localFormatNumber }})
                                | ({{ calculatePercent(contract.maxAmount * stakeValue, contract.balance) }}%)
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="6">
                    <h3>Token</h3>
                    <table border="1" style="width: 100%">
                        <tr>
                            <th style="width:250px">Name</th>
                            <th>Amount</th>
                        </tr>
                        <tr
                            v-for="contract in tokenAddresses"
                            :key="contract.address"
                        >
                            <td>{{ contract.name }}</td>
                            <td colspan="2" v-if="!contract.loaded">
                                <v-progress-circular
                                    size="20"
                                    indeterminate
                                ></v-progress-circular>
                            </td>
                            <td v-if="contract.loaded">
                                {{ contract.balance | removeDecimals | localFormatNumber }} SCRL
                                (${{ contract.balance * tokenPrice | removeDecimals | localFormatNumber }})
                            </td>
                        </tr>
                    </table>

                    -total amount
                    -burned (% of total, % of all unlocked) (total supply(const) - amount)
                    -amount in our contracts (mini table)
                    -amount in LP
                    -amount unlocked (in ppl's hand) (create endpoint also for coingecko)
                    -amount unlocked - amount on stakes (token on wallets)
                    -chart with: burned/unlocked/uncloked-ppl hands
                    -check SCRL/BNB balance of all contracts we use (to know if somebody send extra $ by accident)
                </v-col>
                <v-col cols="6">
                    <h3>Vesting</h3>
                    <b>Main contract status</b>
                    <table border="1" style="width: 100%">
                        <tr>
                            <th>Claimed</th>
                            <th>Balance</th>
                        </tr>
                        <tr>
                            <td>{{ vestingOverallInfo.claimedPercent | localFormatNumber }}%</td>
                            <td>{{ vestingOverallInfo.missingTokens | removeDecimals | localFormatNumber }} SCRL
                                ({{ vestingOverallInfo.balancePercent | localFormatNumber }}%)
                            </td>
                        </tr>
                    </table>
                    <br/>

                    <b>VCs</b>
                    <table border="1" style="width: 100%">
                        <tr>
                            <th>Name</th>
                            <th>Claimed</th>
                            <th>Total</th>
                            <th>Claimed in %</th>
                        </tr>
                        <tr v-for="(row) in vestings">
                            <td>{{ row.name }}</td>
                            <td>
                                {{ row.claimed | removeDecimals | localFormatNumber }}
                                (${{ row.claimed * tokenPrice | removeDecimals | localFormatNumber }})
                            </td>
                            <td>
                                {{ row.total | removeDecimals | localFormatNumber }}
                                (${{ row.total * tokenPrice | removeDecimals | localFormatNumber }})
                            </td>
                            <td>{{ row.claimed / row.total * 100 |localFormatNumber }}%</td>
                        </tr>
                    </table>

                    <br/>

                    <b>Advisors</b>
                    <table border="1" style="width: 100%">
                        <tr>
                            <th>Name</th>
                            <th>Claimed</th>
                            <th>Total</th>
                            <th>Claimed in %</th>
                        </tr>
                        <tr v-for="(row) in advisorVestings">
                            <td>{{ row.name }}</td>
                            <td>
                                {{ row.claimed | removeDecimals | localFormatNumber }}
                                (${{ row.claimed * tokenPrice | removeDecimals | localFormatNumber }})
                            </td>
                            <td>
                                {{ row.total | removeDecimals | localFormatNumber }}
                                (${{ row.total * tokenPrice | removeDecimals | localFormatNumber }})
                            </td>
                            <td>{{ row.claimed / row.total * 100 |localFormatNumber }}%</td>
                        </tr>
                    </table>
                    -amount unlocked
                    -amount to unlock
                    -amount left SCRL on contract (with %)
                    -amount SCRL required to be on contract (- or +)
                    -mini table: our VCs/KOLs: tokens taken/to take/end in days/url to wallet on bsc
                </v-col>
                <v-col cols="6">
                    <h3>MarketMaker</h3>
                    -sizes of MM accounts
                </v-col>
                <v-col cols="6">
                    <h3>Wizards</h3>
                    -total amount
                    -genetic mini-table: % of parts in pool
                    -(external?) browser of wizard images
                    -levels minitable: % of wizards on levels
                </v-col>
                <v-col cols="6">
                    <h3>Lands</h3>
                    -total amount
                    -mini-table with %: rarity/element/type
                    -not yet taken from 1st sale
                </v-col>
                <v-col cols="6">
                    <h3>Staking Map</h3>
                    -max possible day for now
                </v-col>
            </v-row>
        </div>

    </v-container>
</template>


<script>
import config from "../config";
import LandLotteryWhitelist from "../components/land-lottery-whitelist";
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "../services/apiService.vue";
import VestingContract, {vestingContractAddress} from "../services/contracts/vestingContract";
import {StakingContract, stakingContractAddress} from "../services/contracts/stakingContract";
import {LandLotteryContract, landLotteryContractAddress} from "../services/contracts/landLotteryContract";
import {stakingMapAddress, stakingMapV2Address} from "../services/contracts/stakingMapV2Contract";
import TokenContract, {tokenContractAddress} from "../services/contracts/tokenContract";
import BigNumber from "bignumber.js";
import * as timeHelper from "../helpers/timeHelper";
import {Bar as BarChartGenerator} from 'vue-chartjs/legacy'
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const SINGLE_STAKE_TOKEN_VALUE = 100000;

export default {
    components: {PageFiltersSection, LandLotteryWhitelist, BarChartGenerator},
    props: [],
    data() {
        return {
            chartOptions: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'MB'
                        },
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            },
            developSizeChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Minfied + gzip',
                        backgroundColor: 'orange',
                        data: [],
                    },
                    {
                        label: 'Minfied',
                        backgroundColor: 'blue',
                        data: [],
                    },
                ]
            },
            masterSizeChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Minfied + gzip',
                        backgroundColor: 'orange',
                        data: [],
                    },
                    {
                        label: 'Minfied',
                        backgroundColor: 'blue',
                        data: [],
                    },
                ]
            },

            tokenPrice: null,
            contracts: {
                'token': tokenContractAddress,
                'vesting': vestingContractAddress, //TODO: unused?
                'staking_1': {
                    name: '(v1) Wizards',
                    balance: null,
                    maxAmount: 0,
                    address: stakingContractAddress,
                    loaded: false,
                },
                'staking_2': {
                    name: '(v2) Lands',
                    balance: null,
                    maxAmount: 0,
                    address: landLotteryContractAddress,
                    loaded: false,
                },
                'staking_3': {
                    name: 'StakingMap V1',
                    balance: null,
                    maxAmount: 0,
                    address: stakingMapAddress,
                    loaded: false,
                },
                'staking_4': {
                    name: 'StakingMap V2',
                    balance: null,
                    maxAmount: 0,
                    address: stakingMapV2Address,
                    loaded: false,
                },
            },
            vestingOverallInfo: {
                claimedPercent: 0,
                balancePercent: 0,
                missingTokens: 0,
            },
            vestings: [],
            advisorVestings: [],
            tokenAddresses: [
                {
                    name: 'SummonBank',
                    balance: null,
                    address: '0x77944c319D3BD9758107D69bb26Cd480966787ed',
                    loaded: false,
                },
                {
                    name: 'MarketplaceBank',
                    balance: null,
                    address: '',
                    loaded: false,
                },
            ]
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.fetchData();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.loadingStaking = false;
                this.fetchData();
            });
        }
    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        config() {
            return config;
        },
        stakeValue() {
            return SINGLE_STAKE_TOKEN_VALUE;
        }
    },
    methods: {
        getTokenPrice() {
            const url = 'https://api.coingecko.com/api/v3/simple/price?ids=wizarre-scroll&vs_currencies=usd';
            apiServiceVue.request(url).then((res) => {
                this.tokenPrice = res['wizarre-scroll'].usd;
            });
        },
        calculatePercent(initialBalance, actualBalance) {
            if (!initialBalance) {
                return '';
            }
            return Math.round((this.$options.filters.removeDecimals(actualBalance) / initialBalance * 100)) || '';
        },
        fetchHistory() {
            const url = 'https://nft2.wizarre.io/' + 'admin/package-size';
            apiServiceVue.request(url, 'GET', {})
                .then(data => {
                    if (data) {
                        this.developSizeChartData.labels = [];
                        this.developSizeChartData.datasets[0].data = [];
                        this.developSizeChartData.datasets[1].data = [];
                        this.masterSizeChartData.labels = [];
                        this.masterSizeChartData.datasets[0].data = [];
                        this.masterSizeChartData.datasets[1].data = [];
                        for (const row of data) {
                            if ('js' !== row.entity) continue;
                            if ('refs/heads/develop' === row.branch || 'refs/heads/sizess' === row.branch) {
                                this.developSizeChartData.labels.push(timeHelper.timestampToTimeString(row.created_on));
                                this.developSizeChartData.datasets[0].data.push(row.compressed_size / 1024);
                                this.developSizeChartData.datasets[1].data.push(row.minified_size / 1024);
                            }
                            if ('refs/heads/master' === row.branch) {
                                this.masterSizeChartData.labels.push(timeHelper.timestampToTimeString(row.created_on));
                                this.masterSizeChartData.datasets[0].data.push(row.compressed_size / 1024);
                                this.masterSizeChartData.datasets[1].data.push(row.minified_size / 1024);
                            }
                        }
                    }
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Can not fetch chart'
                    });
                })
                .finally(() => {
                });
        },
        async fetchData() {


            this.fetchHistory();

            const tokenContract = new TokenContract(this.web3);
            this.getTokenPrice();
            this.fetchBalanceOfContract('staking_1', tokenContract);

            const stakingContract = new StakingContract(this.web3);
            this.fetchV1StakesLength('staking_1', stakingContract);

            this.fetchBalanceOfContract('staking_2', tokenContract);

            const landLotteryContract = new LandLotteryContract(this.web3);
            this.fetchV2StakesLength('staking_2', landLotteryContract);

            this.fetchBalanceOfContract('staking_3', tokenContract);
            this.fetchBalanceOfContract('staking_4', tokenContract);
            // this.fetchBalanceOfContract('vesting', tokenContract);
            // no function in contract because stake may have many stakes inside

            const vestingContract = new VestingContract(this.web3);
            this.vestingOverallInfo = await this.fetchVestingOverallInfo(vestingContract, tokenContract);
            this.fetchVestings(vestingContract);

            this.tokenAddresses.forEach((obj) => {
                if (obj.address) {
                    tokenContract.getTokenBalanceOf(obj.address)
                        .then((res) => {
                            obj.balance = res;
                            obj.loaded = true;
                        });
                }
            });
        },
        async fetchVestingOverallInfo(vestingContract, tokenContract) {
            const totalClaimed = await vestingContract.totalClaimed();
            const totalVested = await vestingContract.totalVested();
            const balance = await tokenContract.getTokenBalanceOf(vestingContract.contractAddress)

            const claimedPercent = Number(totalClaimed / totalVested) * 100;
            const balancePercent = Number(balance / (totalVested - totalClaimed)) * 100;
            const missingTokens = (new BigNumber(totalVested - totalClaimed - balance)) * -1;

            return {
                claimedPercent,
                balancePercent,
                missingTokens,
            };
        },
        fetchVestings(vestingContract) {
            const addresses = {
                // 'test': '0x8618682bcFBA38B9ffEe8247bbEd9D61f5579BE6',
                // 'OIG': '0x9c182069D7CCEEe4599CF742ceAcb395782EF951',
                // 'Cyberfi': '0x1aF9A204F11037082aE62656A0D37Cb8d4dEb17f',
                // 'X21': '0x4a5BB1c9347A0d4F7e06a29239162f03647d9232',
                // 'AU21': '0x17ec047622C000Df03599026A3B39871EC9384DB',
                // "Crypto321": "0xD990ef33d359EDBB93154a0685972F62ba1f362a",
                // "GGG": "0xE2Dd7a0854D703E71E3c9e4f632D17ddB1C84237",
                // "Shima": "0xb8F03E0a03673B5e9E094880EdE376Dc2caF4286",
                // "Enjinstarer": "0xB9BbB220D5eB660BBB634805dfF8cBDacb732cB4",
                // "Adzilla": "0x4b03F3e84Ff44C93639Ea1Ce80549DAE02Ce8AFa",
                // "Aussie": "0x3f704A8795b8baBBe467482EAA5622bf3d665E82",
                // "DuckDAO": "0xe5Df8468dc3254f13A4Aa30661817C6E40c675A7",
                // "Legion": "0xb5a5B9d508AD42Bb3d529550EB2eF790004Db146",
                // "BMW": "0xEbcb236aa54D01484d8759C146dab14F6E792398",
                // "GameStation": "0x833bc293f33f9b7832b589c744ECB07120a018c6",
                // "LukasAdvisor": "0x055C8C4E4fe99cf483F273274a65B917191D7fe8",
                // "KennethAdvisor": "0xBbEaB20fccCA9BC5b0291CCc1ed92cD010cD5795",
                // "KevinAdvisor": "0x7b6f66ADEaC09C5E1D37b9Dc96A495664E5Fd1D2",
                // "MinhAdvisor": "0x44AE9524267Abc3432Bf994f17c13d635997fE64",
                // "Jack": "0xfbe45eC7Dd5d3E58A51B177992423D6b11508589",
            };
            const loopVar = Object.entries(addresses);
            for (let i = 0; i < loopVar.length; i++) {
                const name = loopVar[i][0];
                const address = loopVar[i][1];
                vestingContract.getVestingsFor(address).then((vestingsGroup) => { //can be many vestings per single address
                    let thisVesting = {
                        claimed: 0,
                        total: 0,
                    };
                    for (let j = 0; j < vestingsGroup.length; j++) {
                        thisVesting.claimed += parseInt(vestingsGroup[j].claimedTokens) || 0;
                        thisVesting.total += parseInt(vestingsGroup[j].totalTokens) || 0;
                    }
                    this.vestings.push({
                        name: name,
                        claimed: thisVesting.claimed,
                        total: thisVesting.total,
                    });
                });
            }
            // console.log(this.vestings);
        },
        fetchBalanceOfContract(contractName, tokenContract) {
            tokenContract.getTokenBalanceOf(this.contracts[contractName].address)
                .then((res) => {
                    this.contracts[contractName].balance = res;
                    this.contracts[contractName].loaded = true;
                });
        },
        fetchV1StakesLength(contractName, stakingContract) {
            stakingContract.getStakesLength()
                .then((res) => {
                    this.contracts[contractName].maxAmount = res;
                });
        },
        fetchV2StakesLength(contractName, landLotteryContract) {
            landLotteryContract.getLandLotteryStakesLength()
                .then((res) => {
                    this.contracts[contractName].maxAmount = res;
                });
        }
    }
};
</script>

<style scoped>
.page-content a {
    color: #ccc !important;
}

.page-content a:hover {
    color: #fff !important;
}
</style>
