const swipelux_api_url = process.env.VUE_APP_SWIPELUX_API_URL;
const swipelux_api_key = process.env.VUE_APP_SWIPELUX_API_KEY;
const swipelux_receive_currency = process.env.VUE_APP_SWIPELUX_RECEIVE_CURRENCY;

if ('undefined' !== typeof module) {
    module.exports = {
        swipelux_api_url,
        swipelux_api_key,
        swipelux_receive_currency
    }
}
